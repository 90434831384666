
app.commonController = app.commonController || {};
app.commonController.defaultAction = {
   
    /**
     * Initialisation de la fonction
     * @returns {undefined}
     */
    init : function () {
        // Touch detection
        if (!('ontouchstart' in document.documentElement)) {
            $('html').removeClass('touch').addClass('no-touch');
        }
        else {
            $('html').removeClass('no-touch').addClass('touch');
        }
        
        // Enable transition after DOM is loaded
        $("body").removeClass("preload");

        $('#goTop').click(function (e) {
            e.preventDefault();
            $('html, body').animate({
                scrollTop: 0
            }, 'slow');
            return false;
        });
    }
};

$(document).ready(function(){
    app.commonController.defaultAction.init();
});
