/**
 * Initialisation de l'application
 */

var app = (function() {
    function App() {}
    App.prototype = {
        clickEvent : (navigator.userAgent.match(/iPad/i)) ? "touchstart" : "click"
    };
    return new App();
})();
