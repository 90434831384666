
app.pagesController = app.pagesController || {};
app.pagesController.defaultAction = {
   
    /**
     * Initialisation de la fonction
     * @returns {undefined}
     */
    base : this,

    init : function () {
        this.switcherLang();
    },

    switcherLang : function () {
        $('.lang-switcher li.current-lang a, .lang-switcher li.current-lang').on('click', function (e) {
            e.preventDefault();
        });
    },


};

$(document).ready(function(){
    app.pagesController.defaultAction.init();
});
